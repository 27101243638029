import Swiper from '@iris.interactive/handcook/public/scripts/libraries/swiper-11'

export default class UtilsComponent {
    static async customerSlider(key, config = {}, navigation = null, pagination = false) {
        const swiperT = `[data-customer-slider="${key}"]`

        const options = {
            slidesPerView: 1,
            speed: 500,
            allowTouchMove: true,
            navigation: navigation
                ? {
                      nextEl: `#customer-slider-${key}-next`,
                      prevEl: `#customer-slider-${key}-prev`,
                  }
                : {},
            mousewheel: {
                forceToAxis: true,
            },
            breakpoints: {
                0: {
                    spaceBetween: 10,
                },
                1025: {
                    spaceBetween: 20,
                },
            },
        }

        $.extend(options, config)

        $(`${swiperT}> *`).wrapAll('<div class="swiper-wrapper"></div>').addClass('swiper-slide')

        if (navigation) {
            $(navigation).append(
                `<div class="customer-slider__navigation"><span id="customer-slider-${key}-prev" class="customer-slider__arrow customer-slider__arrow--prev"></span><span id="customer-slider-${key}-next" class="customer-slider__arrow customer-slider__arrow--next"></span></div>`,
            )
        }

        return await Swiper.create(swiperT, options)
    }

    static customerAnimations() {
        const ratio = 0.25
        const options = {
            root: null,
            rootMargin: '0px',
            threshold: ratio,
        }

        const handleIntersect = (entries, observer) => {
            entries.forEach((e) => {
                if (e.intersectionRatio >= ratio) {
                    $(e.target).addClass('is-loaded')
                    observer.unobserve(e.target)
                }
            })
        }

        const observer = new IntersectionObserver(handleIntersect, options)

        document
            .querySelectorAll('[data-customer-scroll-reveal], [data-customer-animate]')
            .forEach((el) => {
                observer.observe(el)
            })
    }

    static slugify(str) {
        return str
            .normalize('NFD')
            .replace(/[\u0300-\u0301]/g, '')
            .toLowerCase()
            .trim()
            .replace(/[^\w\s-]/g, '')
            .replace(/[\s_-]+/g, '-')
            .replace(/^-+|-+$/g, '')
    }

    static initScrollReveal() {
        const ratio = 0.25
        const options = {
            root: null,
            rootMargin: '0px',
            threshold: ratio,
        }

        const handleIntersect = (entries, observer) => {
            entries.forEach((e) => {
                if (e.intersectionRatio >= ratio) {
                    $(e.target).addClass('is-loaded')
                    observer.unobserve(e.target)
                }
            })
        }

        const observer = new IntersectionObserver(handleIntersect, options)

        document.querySelectorAll('[data-customer-scroll-reveal]').forEach((el) => {
            observer.observe(el)
        })
    }
}
