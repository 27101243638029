import UtilsComponent from '@scripts/components/utils.component'

export default class HomeService {
    swipers = []
    isMobile = window.matchMedia('(max-width: 1024px)').matches
    isEcomode = IRISCollectionCustomer.isEcomodeActive

    constructor() {
        this.initSectionEngine()
        this.initSectionEssentials()
        this.initSectionEvents()
        UtilsComponent.initScrollReveal()
    }

    initSectionEngine() {
        if ($('.section-engine').length > 0) {
            import(
                '@scripts/components/search-engine.component' /* webpackChunkName: "scripts/search-engine.component" */
            ).then(({ default: SearchEngineComponent }) => {
                new SearchEngineComponent()
            })
        }
    }

    async initSectionEssentials() {
        const effect = !this.isMobile ? 'cards' : ''

        this.swipers.essentialsCards = await UtilsComponent.customerSlider('essentials-cards', {
            effect: effect,
            cardsEffect: {
                perSlideOffset: 6,
                perSlideRotate: 6,
            },
            grabCursor: true,
            breakpoints: {
                0: {
                    slidesPerView: 1.2,
                    spaceBetween: 20,
                },
                650: {
                    slidesPerView: 2.2,
                    spaceBetween: 20,
                },
                1025: {
                    slidesPerView: 1,
                    spaceBetween: 0,
                },
            },
        })

        if (!this.isMobile) {
            this.swipers.essentialsPosts = await UtilsComponent.customerSlider(
                'essentials-posts',
                {
                    effect: 'fade',
                    fadeEffect: {
                        crossFade: true,
                    },
                },
                '#section-essentials .section__heading',
            )

            this.swipers.essentialsPosts.controller.control = this.swipers.essentialsCards
        }
    }

    initSectionEvents() {
        if (this.isMobile) {
            this.swipers.agenda = UtilsComponent.customerSlider('agenda', {
                breakpoints: {
                    0: {
                        slidesPerView: 1.15,
                        spaceBetween: 20,
                    },
                    650: {
                        slidesPerView: 2.15,
                        spaceBetween: 20,
                    },
                },
            })
        }
    }
}
